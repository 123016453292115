import React from 'react';
import moment from 'moment';

import { numberFormat } from '../../helpers/utils';

//

export default function HistoryItem({ transaction }) {
  return (
    <div className="mb-4 p-4 flex items-center rounded-xl border-2 border-white border-opacity-20">
      <div className="flex-1">
        <div className="font-bold text-xl">
          {Number(transaction.debit) > 0 ? 'Cashback' : 'Redeem Point'}
        </div>
        <div className="font-bold text-xl text-gray-500">
          {moment(transaction.time).format('DD MMM YYYY, HH:mm')}
        </div>
      </div>
      {transaction.debit > 0 && (
        <div className="font-bold text-green-500">{`Rp ${numberFormat(transaction.debit)}`}</div>
      )}
      {transaction.credit > 0 && (
        <div className="font-bold text-red-500">{`(Rp ${numberFormat(transaction.credit)})`}</div>
      )}
    </div>
  );
}
