export const APP_ENV = process.env.NODE_ENV;
export const APP_NAME = 'ELGROUP MEMBER CARD';
export const APP_KEY = 'EL-MEMBER-CARD';

/* API CREDENTIALS */
const API_URL_ENV = {
  development: 'http://192.168.1.5/elgroup-internal-api/public/api',
  // test: 'http://localhost/mypos-sc-api/public/api',
  production: 'https://internal-api.elgroup.id/api',
};

export const API_URL = API_URL_ENV[process.env.NODE_ENV];
export const API_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  dataType: 'json',
  'X-Requested-With': 'XMLHttpRequest',
};
export const API_HEADER_MULTIPART = {
  Accept: 'application/json',
  // 'Content-Type': 'multipart/form-data',
  dataType: 'json',
  'X-Requested-With': 'XMLHttpRequest',
};

export const API_RESPONSE_STATUS = 'status';
export const API_RESPONSE_MESSAGE = 'message';
export const API_RESPONSE = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

/* LOCAL STORAGE KEY */
export const AUTHENTICATED_USER_STORAGE_KEY = `@${APP_KEY}:authenticatedUser`;
