import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';

import { fetchMemberPromotion } from '../../actions/memberPromotion';

//

export default function MemberPromotion() {
  const { result: memberPromotions } = useSelector((state) => state.memberPromotion);
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(fetchMemberPromotion());
  }, []);

  //

  const showPromotionDetail = (memberPromotion) => {
    setSelected(memberPromotion);
    setIsModalVisible(true);
  };

  const closePromotionDetail = () => {
    setIsModalVisible(false);
  };

  //

  return (
    <div>
      <div className="font-bold text-xl">Program and Promotions</div>
      <div className="py-3 flex overflow-x-scroll">
        {memberPromotions.map((p) => (
          <img
            key={p.id}
            src={p.banner_url}
            alt={p.title}
            className="mr-3 w-80 rounded-xl"
            onClick={() => showPromotionDetail(p)}
          />
        ))}
      </div>

      <Transition.Root show={isModalVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          // initialFocus={initialFocus}
          open={isModalVisible}
          onClose={closePromotionDetail}>
          <div className="flex justify-center">
            {/* MODAL BACKGROUND */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* MODAL CONTENT */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="mt-10 w-5/6 lg:w-2/6 inline-block align-center transform transition-all">
                <div className="px-4 pt-4 pb-8 bg-gray-900 rounded-xl overflow-hidden shadow-xl">
                  <div className="text-right">
                    <button type="button" onClick={closePromotionDetail}>
                      <XIcon className="w-8 h-8" />
                    </button>
                  </div>

                  <div className="mt-2 px-2">
                    <img
                      src={selected?.banner_url}
                      alt={selected?.title}
                      className="w-full rounded-2xl border-2 border-white border-opacity-20"
                    />
                    <div className="mt-4 font-bold text-2xl">{selected?.title}</div>
                    <div className="mt-4 whitespace-pre-wrap">{selected?.content}</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
