import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { TextInput, SubmitButton } from '../../components/Base';
import { requestOtp } from '../../actions/auth';

const authBackground = require('../../assets/img/auth-background.jpg');
const appLogo = require('../../assets/img/elgroup.jpg');

//

export default function Auth() {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const { result } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);
    dispatch(requestOtp(data))
      .then(() => history.push('/auth/otp'))
      .catch(() => {
        setIsSubmittingForm(false);
      });
  };

  return result ? (
    <Redirect from="*" to="/" />
  ) : (
    <div className="bg-black min-h-screen">
      <img src={authBackground} alt="" className="w-full h-60 object-fill" />
      <div className="-mt-10 px-10 pb-10">
        <img
          src={appLogo}
          alt="ELGROUP MEMBER CARD"
          className="w-24 h-24 rounded-lg border-2 border-white border-opacity-20"
        />

        <div className="mt-8">
          <div className="text-2xl font-bold">Welcome To</div>
          <div className="text-2xl font-bold text-gray-500">Member Application</div>
        </div>

        <div className="mt-8 w-12 h-1 bg-gradient-to-r from-yellow-600 via-yellow-100 to-yellow-600"></div>

        <div className="mt-10">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <TextInput
              inputRef={register({ required: true })}
              placeholder="Phone"
              type="phone"
              name="phone"
              errorMessage={errors.phone && 'Please fill the Phone No'}
            />

            <TextInput
              inputRef={register({ required: true })}
              placeholder="Card No"
              type="text"
              name="card_no"
              errorMessage={errors.card_no && 'Please fill the Card No'}
            />

            <div className="text-center mt-6">
              <SubmitButton
                additionalClass="w-full"
                type="submit"
                text="NEXT"
                isLoading={isSubmittingForm}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
