import React, { useState, useEffect } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { TextInput, SubmitButton } from '../../components/Base';
import { requestOtp, submitLogin } from '../../actions/auth';

//

export default function Otp() {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const { requestResult: otpResult } = useSelector((state) => state.auth);
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const [resendOtpTimeDelay, setResendOtpTimeDelay] = useState(0);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  // Setup Resend OTP Time Interval
  useEffect(() => {
    if (resendOtpTimeDelay > 0) {
      setTimeout(() => setResendOtpTimeDelay(resendOtpTimeDelay - 1), 1000);
    }
  }, [resendOtpTimeDelay]);

  const resendOtp = () => {
    // Return if still in delay time
    if (resendOtpTimeDelay > 0) return;

    // Request OTP & Set OTP Resend Delay
    if (!otpResult || !otpResult.data) return;
    const data = {
      phone: otpResult.data.phone,
      card_no: otpResult.data.cardNo,
    };
    dispatch(requestOtp(data));
    setResendOtpTimeDelay(120);
  };

  //

  const onSubmitForm = (data) => {
    if (isSubmittingForm) return;
    if (!otpResult || !otpResult.data) return;

    setIsSubmittingForm(true);

    data.verification_id = otpResult.data.verificationId;
    data.phone = otpResult.data.phone;
    data.card_no = otpResult.data.cardNo;

    dispatch(submitLogin(data))
      .then(() => history.replace('/'))
      .catch(() => setIsSubmittingForm(false));
  };

  if (authenticatedUser) return <Redirect from="*" to="/" />;
  return (
    <div className="bg-black min-h-screen">
      <div className="px-10 py-8">
        <button onClick={() => history.goBack()}>
          <ArrowLeftIcon className="w-8 h-8 text-white" />
        </button>
      </div>
      <div className="mt-4 px-10 pb-10">
        <div className="text-2xl font-bold">
          Please input the 6-digit OTP that sent to your number
        </div>
        <div className="mt-10">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <TextInput
              inputRef={register({ required: true })}
              placeholder="6-digit OTP"
              type="number"
              name="otp"
              errorMessage={errors.otp && 'Please fill the 6-digit OTP'}
            />

            <div className="-mt-3 text-right font-bold">
              <a
                href="#otp"
                className={resendOtpTimeDelay > 0 ? 'text-gray-500' : 'text-gray-100'}
                onClick={resendOtp}>
                Re-send OTP {resendOtpTimeDelay > 0 ? `(${resendOtpTimeDelay})` : ''}
              </a>
            </div>

            <div className="text-center mt-6">
              <SubmitButton
                additionalClass="w-full"
                type="submit"
                text="SUBMIT"
                isLoading={isSubmittingForm}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
