import { combineReducers } from 'redux';

import authenticatedUser from './authenticatedUser';
import auth from './auth';
import memberPromotion from './memberPromotion';

export default combineReducers({
  authenticatedUser,
  auth,
  memberPromotion,
});
