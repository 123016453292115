import { API_URL } from '../constants/env';

//

export const isServerReachable = async () => {
  const timeout = new Promise((resolve, reject) => {
    setTimeout(reject, 5000, 'Request timed out');
  });
  const request = fetch(API_URL);

  try {
    await Promise.race([timeout, request]);
    return true;
  } catch (error) {
    return false;
  }
};

//

export const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  const toFixedFix = (n, prec) => {
    const k = 10 ** prec;
    return `${(Math.round(n * k) / k).toFixed(prec)}`;
  };

  const num = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
  const n = !Number.isFinite(+num) ? 0 : +num;
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s = '';

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

export const financeNumberFormat = (number) => {
  const num = parseFloat(number);
  if (num < 0) {
    return `(${numberFormat(num * -1, 2)})`;
  }

  return numberFormat(num, 2);
};

//

export const getBase64DataType = (base64) => {
  switch (base64.charAt(0)) {
    case 'i': // PNG
      return 'image/png';
    case '/': // JPG, JPEG
      return 'image/jpeg';
    case 'R': // GIF
    case 'U': // webp
    default:
      return '';
  }
};

export const formatBase64Image = (base64) => {
  const dataType = getBase64DataType(base64);
  return `data:${dataType};base64,${base64}`;
};

export const deformatBase64Image = (base64) => {
  const base64String = base64
    .replace('data:image/png;base64,', '')
    .replace('data:image/jpeg;base64,', '');
  return base64String;
};

//

export const initials = (str, length = 2) => {
  try {
    const i = str.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
    return i.join('').substring(0, 2);
  } catch (error) {
    return '';
  }
};

//

export const capitalizedString = (s) => {
  const words = s.toLowerCase().split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
};

const cache = ['', ' ', '  ', '   ', '    ', '     ', '      ', '       ', '        ', '         '];

export function leftPad(string, stringLength, padChar) {
  let str = string;
  let len = stringLength;
  let ch = padChar;

  // convert `str` to a `string`
  str += '';
  // `len` is the `pad`'s length now
  len -= str.length;
  // doesn't need to pad
  if (len <= 0) return str;
  // `ch` defaults to `' '`
  if (!ch && ch !== 0) ch = ' ';
  // convert `ch` to a `string` cuz it could be a number
  ch += '';
  // cache common use cases
  if (ch === ' ' && len < 10) return cache[len] + str;
  // `pad` starts with an empty string
  let pad = '';
  // loop
  while (true) {
    // add `ch` to `pad` if `len` is odd
    if (len & 1) pad += ch;
    // divide `len` by 2, ditch the remainder
    len >>= 1;
    // "double" the `ch` so this operation count grows logarithmically on `len`
    // each time `ch` is "doubled", the `len` would need to be "doubled" too
    // similar to finding a value in binary search tree, hence O(log(n))
    if (len) ch += ch;
    // `len` is 0, exit the loop
    else break;
  }
  // pad `str`!
  return pad + str;
}

export function rightPad(string, stringLength, padChar) {
  let str = string;
  let len = stringLength;
  let ch = padChar;

  // convert `str` to a `string`
  str += '';
  // `len` is the `pad`'s length now
  len -= str.length;
  // doesn't need to pad
  if (len <= 0) return str;
  // `ch` defaults to `' '`
  if (!ch && ch !== 0) ch = ' ';
  // convert `ch` to a `string` cuz it could be a number
  ch += '';
  // cache common use cases
  if (ch === ' ' && len < 10) return str + cache[len];
  // `pad` starts with an empty string
  let pad = '';
  // loop
  while (true) {
    // add `ch` to `pad` if `len` is odd
    if (len & 1) pad += ch;
    // divide `len` by 2, ditch the remainder
    len >>= 1;
    // "double" the `ch` so this operation count grows logarithmically on `len`
    // each time `ch` is "doubled", the `len` would need to be "doubled" too
    // similar to finding a value in binary search tree, hence O(log(n))
    if (len) ch += ch;
    // `len` is 0, exit the loop
    else break;
  }
  // pad `str`!
  return str + pad;
}
