import { REQUEST_OTP, SUBMIT_SIGN_IN, SUBMIT_SIGN_OUT, RESET_STORE } from '../actions/_types';

const initialState = {
  requestResult: null,
  submitResult: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case REQUEST_OTP:
      return {
        ...state,
        requestResult: action.result,
      };

    case SUBMIT_SIGN_IN:
    case SUBMIT_SIGN_OUT:
      return {
        ...state,
        submitResult: action.result,
      };

    case RESET_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
