import React from 'react';
import { useSelector } from 'react-redux';

import HistoryItem from './HistoryItem';

export default function History() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);

  return (
    <div className="bg-black p-6">
      {authenticatedUser?.recent_transactions.map((transaction) => (
        <HistoryItem key={transaction.id} transaction={transaction} />
      ))}
    </div>
  );
}
