import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'react-qr-code';

import MemberPromotion from './MemberPromotion';
import { numberFormat, capitalizedString } from '../../helpers/utils';
import { fetchMemberCard } from '../../actions/auth';

const prioritasCard = require('../../assets/img/prioritas-card.png');
const regularCard = require('../../assets/img/regular-card.png');
const appLogo = require('../../assets/img/elgroup-icon.png');

//

export default function MemberCard() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const [isQrcodeVisible, setIsQrcodeVisible] = useState(false);

  useEffect(() => {
    if (authenticatedUser) fetchMemberData();
  }, []);

  const fetchMemberData = () => {
    dispatch(fetchMemberCard(authenticatedUser.card_id));
  };

  //

  const openQrcode = () => setIsQrcodeVisible(true);

  const closeQrcode = () => {
    fetchMemberData();
    setIsQrcodeVisible(false);
  };

  //

  return (
    <div className="bg-black">
      <div className="p-6">
        <div>
          <div className="font-light font-xs text-center">Your Account Balance</div>
          <div className="font-bold text-2xl text-center">
            Rp {authenticatedUser ? numberFormat(authenticatedUser.balance) : ''}
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-center">
            <div className="relative w-64" onClick={openQrcode}>
              <img
                src={authenticatedUser?.member_type === 'PRIORITAS' ? prioritasCard : regularCard}
                alt={`${authenticatedUser?.member_type} MEMBER CARD`}
                className="w-full rounded-2xl border-2 border-white border-opacity-20"
              />
              <div className="absolute top-44 -right-20 text-2xl text-right transform rotate-90">
                {authenticatedUser?.card_no}
              </div>
              <div className="absolute right-6 bottom-6 text-xl text-right">
                {capitalizedString(authenticatedUser?.name)}
              </div>
            </div>
          </div>
          <div className="mt-2 font-xs text-center">Tap the Card to use for Payment</div>
        </div>

        <div className="mt-10">
          <MemberPromotion />
        </div>
      </div>

      <Transition.Root show={isQrcodeVisible} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          // initialFocus={initialFocus}
          open={isQrcodeVisible}
          onClose={closeQrcode}>
          <div className="flex justify-center">
            {/* MODAL BACKGROUND */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* MODAL CONTENT */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="mt-10 w-5/6 lg:w-2/6 inline-block align-center transform transition-all">
                <div className="px-4 pt-4 pb-8 bg-gray-900 rounded-xl overflow-hidden shadow-xl">
                  <div className="text-right">
                    <button type="button" onClick={closeQrcode}>
                      <XIcon className="w-8 h-8" />
                    </button>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <div className="p-4 bg-white">
                      <QRCode
                        value={authenticatedUser?.card_id}
                        size={275}
                        logo={appLogo}
                        logoSize={80}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="text-center">Please show the QR Code</div>
                    <div className="text-center">to our Staff</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
