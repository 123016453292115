export const RESET_STORE = 'RESET_STORE';

/* AUTHENTICATION */
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const RETRIEVE_AUTHENTICATED_USER = 'RETRIEVE_AUTHENTICATED_USER';
export const EMPTY_AUTHENTICATED_USER = 'EMPTY_AUTHENTICATED_USER';
export const REQUEST_OTP = 'REQUEST_OTP';
export const SUBMIT_SIGN_IN = 'SUBMIT_SIGN_IN';
export const SUBMIT_SIGN_OUT = 'SUBMIT_SIGN_OUT';

export const FETCH_MEMBER_PROMOTION = 'FETCH_MEMBER_PROMOTION';
