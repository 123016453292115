import React, { useState } from 'react';
import { CreditCardIcon, CalendarIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import MemberCard from '../MemberCard';
import History from '../History';
import { submitSignOut } from '../../actions/auth';

//

export default function Home() {
  const history = useHistory();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('home');
  const tabs = [
    {
      key: 'home',
      icon: <CreditCardIcon className="w-6 h-6" />,
      title: 'MY CARD',
      content: <MemberCard />,
    },
    {
      key: 'history',
      icon: <CalendarIcon className="w-6 h-6" />,
      title: 'HISTORY',
      content: <History />,
    },
  ];

  const onPressLogout = () => {
    dispatch(submitSignOut());
    history.replace('/auth');
  };

  //

  const renderTabContent = () => {
    const tab = tabs.find((t) => t.key === selectedTab);
    if (tab) return tab.content;

    return <div />;
  };

  return (
    <div className="bg-black min-h-screen">
      <div className="px-6 pt-4 flex">
        <div className="flex-1">
          <div className="text-2xl font-bold">Member Card</div>
          <div className="-mt-1 text-xl font-bold text-gray-500">{authenticatedUser?.card_no}</div>
        </div>
        <div>
          <button className="font-bold text-yellow-400" onClick={onPressLogout}>
            Log Out
          </button>
        </div>
      </div>

      <div className="mt-4 flex">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setSelectedTab(tab.key)}
            className={`flex-1 py-3 border-b-2 ${
              selectedTab === tab.key
                ? 'text-yellow-400 border-yellow-400'
                : 'text-gray-500 border-gray-500'
            }`}>
            <div className="flex items-center justify-center">
              {tab.icon}
              <div className="ml-1">{tab.title}</div>
            </div>
          </button>
        ))}
      </div>
      <div>{renderTabContent()}</div>
    </div>
  );
}
