import Swal from 'sweetalert2';
import { REQUEST_OTP, SUBMIT_SIGN_IN, SUBMIT_SIGN_OUT } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';
import { setAuthenticatedUser, removeAuthenticatedUser } from './authenticatedUser';

/**
 * @param data      phone, card_no
 */
export const requestOtp = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/request-member-otp`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: REQUEST_OTP, result: response });
            // Swal.fire('Request Success', '', 'success');
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Request Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};

/**
 * @param data      verification_id, otp, phone, card_no
 */
export const submitLogin = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/validate-member-otp`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch(setAuthenticatedUser(response.data));
            dispatch({ type: SUBMIT_SIGN_IN, result: response });
            Swal.fire('Login Success', 'Welcome to Membership Application', 'success');
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Login Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};

//

export const fetchMemberCard = (cardId) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member/member-card?card_id=${cardId}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            return dispatch(setAuthenticatedUser(response.data));

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Fetch Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};

//

export const submitSignOut = () => {
  return (dispatch) => {
    dispatch(removeAuthenticatedUser());

    const response = {
      [API_RESPONSE_STATUS]: API_RESPONSE.SUCCESS,
      [API_RESPONSE_MESSAGE]: "You've been Logged out successfully",
    };
    dispatch({ type: SUBMIT_SIGN_OUT, result: response });

    Swal.fire('Logout Success', response[API_RESPONSE_MESSAGE], 'success');
  };
};
